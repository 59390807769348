import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import BannerImageWithOverlay from '../components/Page/BannerImageWithOverlay'
import ProductList from '../components/Shop/ProductList'

const shop = ({data}) => {

	if (data.site.siteMetadata.config.shopEnabled) {
		return (
			<Layout>
				<BannerImageWithOverlay image={data.bannerImage.childImageSharp.gatsbyImageData}>
					<div className={`p-4 md:p-12 bg-bbf-cool-green bg-opacity-95 text-white`}>
						<h2 className={`text-4xl xl:text-5xl mb-6`}>Boswell Book Festival Shop</h2>
						<h3 className={`text-2xl xl:text-3xl`}>Products available to buy and collect at the Festival</h3>
					</div>
				</BannerImageWithOverlay>
				<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
					<div className={`p-4 md:p-12`}>
						<ProductList products={data.products.nodes} />
					</div>
				</section>
			</Layout>
		)
	}

	// Render coming soon page
	return (
		<Layout>
			<BannerImageWithOverlay image={data.bannerImage.childImageSharp.gatsbyImageData}>
				<div className={`p-4 md:p-12 bg-bbf-cool-green bg-opacity-95 text-white`}>
					<h2 className={`text-4xl xl:text-5xl mb-6`}>Boswell Book Festival Shop</h2>
					<h3 className={`text-2xl xl:text-3xl`}>Coming Soon!</h3>
				</div>
			</BannerImageWithOverlay>
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className={`p-4 md:p-12`}>
					{/*<ProductList products={products} />*/}
				</div>
			</section>
		</Layout>
	)
}

export const query = graphql`query {
	bannerImage: file(relativePath: {eq: "banner/Boswell-Book-Festival-Shop.jpg"}) {
		childImageSharp {
			gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
		}
	}
	products: allPrismicProduct (filter : { data : { product_active: { eq: true } } }) {
		nodes {
			uid
			id
			prismicId
			data {
				product_name {
					text
				}
				product_description {
					text
					html
				}
				product_type
				product_image_1 {
					alt
					dimensions {
						width
						height
					}
					url
				}
				product_price
				product_quantity
			}
		}
	}
	site {
		siteMetadata {
			title
			config {
				boxOfficeEnabled
				festivalYear
				shopEnabled
			}
		}
	}
}`

export default shop
