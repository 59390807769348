import React from 'react'

const SnipcartButton = (props) => {

	const { product, buttonText, disabled, onClickHandler } = props

	const clickHandler = () => {
		return onClickHandler(product)
	}

	return (
		<button
			className={`snipcart-add-item transition-all w-full ${
				disabled === true ? 'bg-gray-400 cursor-not-allowed' : 'bg-bbf-yellow'
			} py-3 px-6 uppercase shadow transition-all focus:outline-none focus:shadow-outline`}
			type="button"
			disabled={disabled}
			onClick={clickHandler}
			data-item-id={product.uid}
			data-item-name={product.data.product_name.text}
			data-item-price={product.data.product_price}
			data-item-image={product.data.product_image_1.url}
			data-item-description={product.data.product_description.text}
			data-item-url={`/shop`}
		>
			{buttonText ? buttonText : 'Add to basket'}
		</button>
	)
}

export default SnipcartButton
