import React from 'react'
import { Link } from 'gatsby'
import SnipcartButton from "./SnipcartButton"

const ProductList = (props) => {

	const { products } = props

	const addToBasket = (product) => {
		// I think Snipcart will handle this
		// console.log(product)
	}

	const ProductListItem = (props) => {
		const { product } = props
		const { data } = product

		return (
			<div className={`shadow-md bg-white flex flex-col w-full p-4 transition hover:scale-105`}>
				{/*<Img objectFit={`cover`} className={`h-48`} fluid={data.event_image.childImageSharp.fluid} alt={data.event_title} />*/}
				<Link to={`/shop/${product.uid}`}>
					<div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
						<img className={`w-full h-full object-center object-cover lg:w-full lg:h-full`} src={data.product_image_1.url} alt={data.product_image_1.alt}/>
					</div>
				</Link>
				<h3 className={`mt-4`}>{data.product_name.text}</h3>
				<p className={`text-2xl mt-2 mb-4`}>£{data.product_price}</p>
				{/* TODO: Add in the snipcart data attributes here */}
				<SnipcartButton product={props.product} disabled={false} onClickHandler={addToBasket}/>
			</div>
		)
	}

	return (
		<div className={`grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3`}>
			{products.map((product, index) => {
				return <ProductListItem product={product} key={index} />
			})}
		</div>
	)

}

export default ProductList
